<template>
  <v-container fluid class="h-100">
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="9" class="pt-1">
          <span class="headline font-weight-medium">{{ title }}</span>
        </v-col>
        <v-col cols="3" class="pt-1">
          <v-btn
            v-if="selected_product == null"
            dark
            color="#023145"
            width="140"
            class="float-right ml-3"
            @click="handleStore"
          >
            Guardar
          </v-btn>
          <v-btn
            v-else
            dark
            color="#023145"
            width="140"
            class="float-right ml-3"
            @click="handleUpdate"
          >
            Actualizar
          </v-btn>
          <v-btn dark color="red" class="float-right" @click="handleCancel">
            Cancelar
          </v-btn>
        </v-col>
      </v-row>

      <v-row style="height: 88% !important;">
        <v-col cols="6">
          <v-card class="h-100">
            <v-card-title class="pt-1 pb-1">
              <span class="subtitle-1 font-weight-bold">Características</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-1 pb-1">
              <template>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="productForm.name"
                      label="Nombre"
                      outlined
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="productForm.purchase_price"
                      label="Precio de compra"
                      outlined
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      ref="minimum_stock"
                      v-model="productForm.minimum_stock"
                      label="Stock mínimo"
                      type="number"
                      outlined
                      :rules="[
                        () =>
                          /*!!productForm.minimum_stock ||
                          'Es necesario ingresar el stock mínimo',*/
                          (productForm.control == false && productForm.minimum_stock == 0) ||
                          'Es necesario ingresar el stock mínimo',
                      ]"
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- <v-col cols="6">
                                    <v-text-field 
                                        v-model="productForm.barcode" 
                                        label="Código de barras fabricante" 
                                        outlined 
                                        hide-details>
                                    </v-text-field>
                                </v-col> -->
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      ref="category_id"
                      v-model="productForm.category_id"
                      :items="categories"
                      label="Categoría"
                      outlined
                      :item-text="customText"
                      item-value="id"
                      :rules="[
                        () =>
                          !!productForm.category_id ||
                          'Es necesario seleccionar una categoria',
                      ]"
                      hide-details="auto"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="productForm.brand_id"
                      :items="brands"
                      label="Marca"
                      outlined
                      :item-text="customText"
                      item-value="id"
                      clearable
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <!-- <v-col cols="12">
                    <v-autocomplete
                      v-model="productForm.brand_id"
                      :items="brands"
                      label="Marca"
                      outlined
                      :item-text="customText"
                      item-value="id"
                      clearable
                      hide-details
                    ></v-autocomplete>
                  </v-col> -->
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-select
                      ref="unit_id"
                      v-model="productForm.unit_id"
                      :items="units"
                      label="Unidad de medida base"
                      outlined
                      :item-text="customText"
                      item-value="id"
                      :rules="[
                        () =>
                          !!productForm.unit_id ||
                          'Es necesario seleccionar unidad de medida',
                      ]"
                      hide-details="auto"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      ref="base_price"
                      v-model="productForm.base_price"
                      label="Precio de venta"
                      type="number"
                      outlined
                      :rules="[
                        () =>
                          !!productForm.base_price ||
                          'Es necesario ingresar el precio de venta',
                      ]"
                      hide-details="auto"
                      required
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <!-- <v-col cols="12">
                    <v-text-field
                      ref="base_price"
                      v-model="productForm.base_price"
                      label="Precio de venta"
                      type="number"
                      outlined
                      :rules="[
                        () =>
                          !!productForm.base_price ||
                          'Es necesario ingresar el precio de venta',
                      ]"
                      hide-details="auto"
                      required
                    >
                    </v-text-field>
                  </v-col> -->
                </v-row>

                <v-row>
                  <!-- <v-col cols="12">
                    <v-text-field
                      ref="minimum_stock"
                      v-model="productForm.minimum_stock"
                      label="Stock mínimo"
                      type="number"
                      outlined
                      :rules="[
                        () =>
                          !!productForm.minimum_stock ||
                          'Es necesario ingresar el stock mínimo',
                      ]"
                      hide-details="auto"
                      required
                    >
                    </v-text-field>
                  </v-col> -->
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-switch
                      v-model="productForm.compound"
                      class="mt-3"
                      label="Producto compuesto"
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      @change="handleChangerHasCompuesto"
                    ></v-switch>
                  </v-col>

                  <v-col cols="6" v-if="productForm.compound">
                    <v-autocomplete
                      ref="product_id"
                      v-model="productForm.recipe_id"
                      :items="recetas"
                      label="Seleccione composición"
                      :item-text="customText"
                      item-value="id"
                      clearable
                      outlined
                      dense
                      hide-details="auto"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-switch
                      v-model="productForm.control"
                      class="mt-3"
                      label="Control de Stock"
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      @change="handleChangerHasControl"
                    ></v-switch>
                  </v-col>

                  <!--<v-col cols="6" v-if="productForm.compound">
                    <v-autocomplete
                      ref="product_id"
                      v-model="productForm.recipe_id"
                      :items="recetas"
                      label="Seleccione composición"
                      :item-text="customText"
                      item-value="id"
                      clearable
                      outlined
                      dense
                      hide-details="auto"
                    ></v-autocomplete>
                  </v-col>-->
                </v-row>

                <!-- <v-row>
                  <v-col cols="12">
                    <v-switch
                      v-model="productForm.has_commission"
                      class="mt-3"
                      label="Comisión para el vendedor"
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      @change="handleChangerHasCommission"
                    ></v-switch>
                  </v-col>
                </v-row> -->
                <!-- <v-row>
                  <v-col cols="6" v-if="productForm.has_commission">
                    <v-combobox
                      ref="type"
                      v-model="productForm.type_commission"
                      label="Tipo de comisión"
                      :items="types_commissions"
                      outlined
                      hide-details="auto"
                    >
                    </v-combobox>
                  </v-col>
                  <v-col cols="6" v-if="productForm.has_commission">
                    <v-text-field
                      v-if="productForm.type_commission == 'VALOR'"
                      v-model="productForm.commission"
                      label="Comisión"
                      outlined
                      type="number"
                      prefix="S/"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-if="productForm.type_commission == 'PORCENTAJE'"
                      v-model="productForm.commission"
                      label="Comisión"
                      outlined
                      type="number"
                      prefix="%"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="12">
                    <v-card>
                      <v-card-title class="pt-2 pb-1">
                        <span class="subtitle-2 font-weight-black">
                          Código de barras
                        </span>
                        <v-switch
                          v-model="productForm.has_barcode"
                          class="ma-2 mt-0 float-right"
                          hide-details
                          @change="handleHasBarcode"
                        ></v-switch>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text
                        v-if="productForm.has_barcode"
                        style="text-align: center"
                      >
                        <barcode v-model="productForm.barcode"></barcode>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" class="h-100">
          <template>
            <v-container class="pt-0 pb-0 h-100">
              <v-row class="h-100">
                <v-col cols="12" class="pt-0 pb-0">
                  <v-card class="h-100">
                    <v-card-title class="pt-1 pb-1">
                      <span class="subtitle-1 font-weight-bold">Precios</span>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="success"
                        small
                        dark
                        @click="handleViewAddPrice"
                      >
                        <v-icon class="mr-2" size="15"
                          >fas fa-plus-circle</v-icon
                        >
                        Agregar
                      </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="pt-1 pb-1 h-80">
                      <template>
                        <v-row class="h-100">
                          <v-col cols="12">
                            <v-data-table
                              :headers="headers"
                              :items="product_prices"
                              :items-per-page="10"
                              class="elevation-1 w-table"
                              disable-pagination
                              dense
                              hide-default-footer
                            >
                              <template v-slot:item.price="{ item }">
                                <span>{{ item.price | currency("S/") }}</span>
                              </template>

                              <template v-slot:item.actions="{ item }">
                                <v-btn
                                  icon
                                  dark
                                  color="red"
                                  class="float-right"
                                  @click="handleDeletePrice(item)"
                                >
                                  <v-icon size="16">fas fa-times-circle</v-icon>
                                </v-btn>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-col>
      </v-row>
    </template>

    <add-prices ref="addPrices"></add-prices>
    <add-unit ref="addUnit"></add-unit>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import router from "@/router/index";
import ArrayTools from "../../../helpers/ArrayTools";
import OfficeLogin from "../../../components/auth/OfficeLogin";
import AddPrices from "../../../components/products/AddPrices";
import AddUnit from "../../../components/products/AddUnit";
import VueBarcode from "@chenfengyuan/vue-barcode";
import Tools from "../../../helpers/Tools";
import ProductionApi from '../../../apis/Production';

export default {
  name: "ProductDetail",
  components: {
    AddPrices,
    AddUnit,
    OfficeLogin,
    barcode: VueBarcode,
  },
  data() {
    return {
      itemsBreadcrumbs: [
        {
          text: "Logística",
          disabled: true,
          href: "",
        },
        {
          text: "Productos",
          disabled: false,
          href: "/app/logistica/productos",
        },
      ],
      title: null,
      fields_show_all: true,
      fields_show: [],
      name_price: null,
      new_price: null,
      prices: [],
      headers: [
        { text: "NOMBRE", value: "name", sortable: false },
        { text: "PRECIO", value: "price", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      types_commissions: ["PORCENTAJE", "VALOR"],
      productForm: {
        id: null,
        name: null,
        sku: null,
        barcode: null,
        category_id: null,
        brand_id: null,
        unit_id: null,
        base_price: null,
        minimum_stock: 0,
        has_commission: false,
        type_commission: null,
        commission: null,
        purchase_price: null,
        has_barcode: true,
        compound: false,
        control: true,
        recipe_id: null,
      },
      recetas: [],
      auxControl: true
    };
  },
  computed: {
    ...mapState("categories", ["categories"]),
    ...mapState("brands", ["brands"]),
    ...mapState("products", [
      "selected_product",
      "product",
      "product_prices",
      "products",
    ]),
    ...mapState("units", ["units"]),
  },
  methods: {
    ...mapActions("categories", ["getCategories"]),
    ...mapActions("brands", ["getBrands"]),
    ...mapActions("units", ["getUnits"]),
    ...mapActions("products", [
      "store",
      "update",
      "show",
      "selectProduct",
      "emptyProducts",
      "setProductUnits",
      "removeProductUnit",
      "setProductPrices",
      "removeProductPrice",
      "emptyProduct",
    ]),
    clearData() {
      this.fields_show_all = true;
      this.productForm.id = null;
      this.productForm.name = null;
      this.productForm.sku = null;
      this.productForm.barcode = null;
      this.productForm.category_id = null;
      this.productForm.brand_id = null;
      this.productForm.unit_id = null;
      this.productForm.base_price = null;
      this.productForm.minimum_stock = 0;
      this.productForm.has_commission = false;
      this.productForm.type_commission = null;
      this.productForm.commission = null;
      this.productForm.purchase_price = null;
      this.productForm.compound = false;
      this.productForm.recipe_id = null;
      this.setProductUnits([]);
      this.setProductPrices([]);
      this.selectProduct(null);
      this.clearErrors();
    },
    setProduct(product) {
      this.productForm.id = product.id;
      this.productForm.name = product.name;
      this.productForm.sku = product.sku;
      this.productForm.barcode = product.barcode;
      this.productForm.category_id = product.category_id;
      this.productForm.brand_id = product.brand_id;
      this.productForm.unit_id = product.unit_id;
      this.productForm.base_price = product.base_price;
      this.productForm.minimum_stock = product.minimum_stock;
      this.productForm.has_commission = product.has_commission;
      this.productForm.type_commission = product.type_commission;
      this.productForm.commission = product.commission;
      this.productForm.purchase_price = product.purchase_price;
      this.productForm.control = product.control;

      this.productForm.compound =
        product.compound === 1 || product.compound === true ? true : false;
      this.productForm.recipe_id = product.recipe_id;

      if (product.prices) {
        this.setProductPrices(product.prices);
      }

      if (product.units) {
        this.setProductUnits(product.units);
      }
    },
    handleCancel() {
      this.clearData();
      router.push("/app/logistica/productos");
    },
    customText(item) {
      let text = item.name;

      return text;
    },
    async handleStore() {
      
      console.log('guarda productos');
      if (!this.existErrors()) {
        let entityProduct = Object.assign({}, this.productForm);
        entityProduct["product_prices"] = this.product_prices;
        await this.store(entityProduct);
        this.clearData();
      }
    },
    async handleUpdate() {
      if (!this.existErrors()) {
        let entityProduct = Object.assign({}, this.productForm);
        entityProduct["product_prices"] = this.product_prices;
        await this.update(entityProduct);
        this.clearData();
        router.push("/app/logistica/productos");
      }
    },
    async getProductData(product_search) {
      await this.show(product_search);
      let itemProduct = JSON.parse(JSON.stringify(this.product));
      this.setProduct(itemProduct);
    },
    existErrors() {
      let hasErrors = false;

      Object.keys(this.productForm).forEach((f) => {
        
        if (this.$refs[f]) {
          
          
          if (!this.productForm[f] && this.auxControl == true){
            
            hasErrors = true;
            
          } 
          
          this.$refs[f].validate(true);
          
        }
      });

      

      return hasErrors;
    },
    clearErrors() {
      Object.keys(this.productForm).forEach((f) => {
        if (this.$refs[f]) this.$refs[f].reset();
      });
    },
    handleViewAddPrice() {
      this.$refs.addPrices.show();
    },
    handleDeletePrice(product_price) {
      let indexFounded = ArrayTools.getIndexById(
        this.product_prices,
        product_price.id
      );
      if (indexFounded > -1) {
        this.removeProductPrice(indexFounded);
      }
    },
    handleViewAddUnit() {
      this.$refs.addUnit.show();
    },
    handleChangerHasCompuesto(value) {
      this.productForm.control = false;
      this.auxControl = this.productForm.control;
    },
    handleChangerHasControl(value) {
      //this.productForm.control = false;
      this.auxControl = this.productForm.control;
    },
    handleChangerHasCommission(value) {
      this.productForm.type_commission = null;
      this.productForm.commission = null;
    },
    handleHasBarcode(value) {
      if (!this.productForm.has_barcode) {
        this.productForm.barcode = null;
      } else {
        if (this.product) {
          this.productForm.barcode = this.product.barcode;
        } else {
          this.productForm.barcode = Tools.generateBarcode();
        }
        // if (this.actionCrud == "create") {
        //   this.productForm.barcode = Tools.generateBarcode();
        // } else {
        //   if (this.productForm.barcode_saved) {
        //     this.productForm.barcode = this.productForm.barcode_saved;
        //   } else {
        //     this.productForm.barcode = Tools.generateBarcode();
        //   }
        // }
      }
    },
    handleProductCompound(value) {
      if (!this.productForm.compound) {
        this.productForm.recipe_id = null;
      }
    },
    loadData(){
        ProductionApi.listRecetas().then((value) =>{
            console.log(value);
            this.recetas = value.data;
            console.log(this.recetas);
        });
    }
  },
  created() {
    this.productForm.barcode = Tools.generateBarcode();
    console.log(this.productForm.barcode);
    // this.emptyProducts();
    this.setProductPrices([]);
    this.setProductUnits([]);
    if (this.selected_product) {
      this.title = "Actualizar datos de producto";
      this.getProductData(this.selected_product);
    } else {
      this.title = "Nuevo producto";
      this.selectProduct(null);
    }
    this.getBrands();
    this.getUnits();
    this.loadData();
  },
  destroyed() {
    this.emptyProduct();
  },
};
</script>

<style scoped>
.h-50 {
  height: 50% !important;
}

.h-80 {
  height: 80% !important;
}
</style>
